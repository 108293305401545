import { fetchData, useSWRFetch } from "../hooks/useFetch";
import { hash } from "../hooks/useHashRouter";


/**
 * All api requests in one place
 */

export const GetDashboardStatistics = (params) => useSWRFetch(hash(`GetDashboardStatistics`), 'GET', params);

export const GetSalonDataBySlug = (params) => useSWRFetch(hash(`GetSalonDataBySlug`), 'GET', params, {}, true);


export const GetAllAppointmentsBySalon = (params) => useSWRFetch(hash(`GetAllAppointmentsBySalon`), 'GET', params);
export const GetAllAppointmentsByClient = (params) => useSWRFetch(hash(`GetAllAppointmentsByClient`), 'GET', params);

export const GetAvailableTimeBySalon = (params) => useSWRFetch(hash(`GetAvailableTimeBySalon`), 'GET', params, {}, true);


export const GetSalonByMember = (params) => useSWRFetch(hash(`GetSalonByMember`), 'GET', params);


export const UpdateSalonBaseData = (data, params) => fetchData(hash(`UpdateSalonBaseData`), 'PUT', params, data);


export const ChangeUserlanguage = (data, params) => fetchData(hash(`ChangeUserlanguage`), 'PUT', params, data);
export const UpdateUserBaseData = (data, params) => fetchData(hash(`UpdateUserBaseData`), 'PUT', params, data);
export const UpdateUserContacts = (data, params) => fetchData(hash(`UpdateUserContacts`), 'PUT', params, data);


export const NewAppointment = (data, params) => fetchData(hash(`NewAppointment`), 'POST', params, data);
export const ChangeAppointmentStatus = (data, params) => fetchData(hash(`ChangeAppointmentStatus`), 'POST', params, data);

export const NewTicket = (data, params) => fetchData(hash(`NewTicket`), 'POST', params, data);


export const GetWorkTimeBySalon = (params) => useSWRFetch(hash(`GetWorkTimeBySalon`), 'GET', params);
export const UpdateWorkTime = (data, params) => fetchData(hash(`UpdateWorkTime`), 'PUT', params, data);