import { useEffect } from "react";

export default function ClearCookies() {
    const removeAllCookiesOnce = () => {
        if (document.cookie.includes("_cc=true")) return;

        if (document.cookie.includes("isAuth=true")) {
            document.cookie.split(";").forEach((cookie) => {
                const name = cookie.split("=")[0].trim();
                document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
            });

            // Set a flag to indicate cookies are cleared
            document.cookie = "_cc=true; path=/";
        }
    };

    useEffect(() => {
        removeAllCookiesOnce();
    }, []); // Runs once when the component mounts

    return null;
}
