import { Link } from "react-router-dom";
import { ArrowBottom } from "../../../../icons";
import { useTranslation } from "react-i18next";


export default function About() {

    const { t } = useTranslation()

    return (
        <section className="max-w-6xl mx-auto w-full px-4 grid lg:grid-cols-2 grid-cols-1 lg:gap-4 gap-20 py-20">
            <div className="flex flex-col justify-center">
                <div className="flex flex-col lg:w-4/5 w-full">
                    <h2 className="textBody-m2 text-fontColor">{t('about_section_1')}</h2>
                    <p className="textBody-s3 mt-2 mb-6 text-fontColor2">{t('about_section_2')}</p>

                    <Link
                        to="/?auth=register&t=barbers"
                        className="textBody-s2 bg-secondary text-white py-2.5 sm:px-8 px-4 rounded transition-all duration-200 w-fit hover:shadow-[0_0_10px_0_#2d54de]"
                    >
                        <span className="line-clamp-1">{t('about_section_3')}</span>
                    </Link>
                </div>
            </div>

            <div className="">
                <div className="pb-4 flex items-center gap-2 justify-center">
                    <ArrowBottom className="w-5 h-5" />
                    <h3>{t('about_section_4')}</h3>
                    <ArrowBottom className="w-5 h-5" />
                </div>
                <div className="shadow-[0_0_10px_0_#2d54de] border-secondary border-solid border-[2px] min-h-40">
                    <img
                        src="./booking-screenshot.jpg"
                        alt="booking screenshot"
                    />
                </div>
            </div>
        </section>
    )
}