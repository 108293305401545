import { useState } from "react";
import axiosClient from "../lib/axiosClient"
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import useCookies from "./useCookies";
import { useAlerts } from "../context/AlertsContext";
import { hash } from "./useHashRouter";
import { useTranslation } from "react-i18next";
import { isNull } from "../Helpers/utils";
import getEnv from "../Helpers/env";


export const useAuth = () => {

    const { t } = useTranslation()


    const { slug } = useParams();


    const { addAlert } = useAlerts();


    // get appDashboardUrl url
    const appDashboardUrl = getEnv('appDashboardUrl') || "/";


    const navigate = useNavigate()

    const { removeGlobalCookie, setGlobalCookie, getCookie } = useCookies()


    // check if found cookie "_uln" or not
    const isAuth = getCookie("_uln") || null;


    // State to store 'isLoading' value, for all requests in this page
    const [isLoading, setIsLoading] = useState(false)



    // get & set sanctum tokens
    const csrf = () => axiosClient.get('sanctum/csrf-cookie');


    // redirect user after login
    const redirectUrl = (role, isNew) => {
        if (role === "client") {

            if (isNull(slug)) {
                navigate('/')
            } else {
                // Remove all query parameters from the current URL
                const url = window.location.origin + window.location.pathname;
                window.history.replaceState(null, '', url);
            }
            
        } else {
            const nextUrlPath = isNew ? 'salon?new=1' : 'appointments';
            const nextUrl = appDashboardUrl + nextUrlPath;
            window.location.href = nextUrl;
        }
    }


    /**
     * Get auth user data
     */
    const authUser = useSWR(isAuth ? hash("auth/user") : null, () =>
        axiosClient.get(isAuth ? hash("auth/user") : null)
            .then(async res => {
                const userData = res.data;

                return userData;
            })
            .catch(async error => {
                if (error.response.data.message === 'Unauthenticated.') {
                    removeGlobalCookie("_uln");
                    removeGlobalCookie("sharely_cookie"); // remove also this cookie (just now, because login strictur updated)

                    navigate('/')
                }
            })
    );


    /**
     * Login
    */
    const login = async ({ showVerificationBox, setErrors, isNew = false, ...props }) => {
        try {
            setIsLoading(true)

            setErrors({})

            await csrf();

            const { data } = await axiosClient.post(hash('auth/login'), {...props, isn: isNew});

            if (data.is_ok === true) {

                const { is_mssg_sent, is_logged_in, token, user } = data?.data ?? {};

                if (is_mssg_sent) {
                    showVerificationBox(true)
                    addAlert(data?.message ?? t('mssg_1'), 'success');
                }

                if (is_logged_in) {
                    addAlert(data?.message ?? t('mssg_1'), 'success');

                    // user's remember token
                    setGlobalCookie("_uln", token ?? "");

                    // refresh user data
                    authUser.mutate()

                    // redirect
                    redirectUrl(user?.role, isNew);
                }

            } else {
                addAlert(data?.message ?? t('mssg_2'), 'error');
            }

        } catch (error) {
            // return validation errors
            if (error?.response?.data?.type === "validation") {
                setErrors(error?.response?.data?.errors ?? {});
            }

            else if (error?.response?.data?.is_ok === false) {
                // show danger message
                addAlert(error?.response?.data?.message ?? t('mssg_2'), 'error');

                if (error?.response?.data?.data?.is_incorrect_code) {
                    setErrors({ code: error?.response?.data?.message ?? t('mssg_2') })
                }
            }

        } finally {
            setIsLoading(false)
        }

        return {};
    }


    /**
     * Register
    */
    const register = async ({ showVerificationBox, setErrors, ...props }) => {
        try {
            setIsLoading(true)

            setErrors({})

            await csrf();

            const { data } = await axiosClient.post(hash('auth/register'), props);

            if (data.is_ok === true) {

                const { is_mssg_sent, is_registered } = data?.data ?? {};

                if (is_mssg_sent) {

                    showVerificationBox(true)

                    addAlert(data?.message ?? t('mssg_1'), 'success');

                }

                if (is_registered) {

                    addAlert(data?.message ?? t('mssg_1'), 'success');

                    await login({ setErrors, isNew: true, ...props })
                }

            } else {
                addAlert(data?.message ?? t('mssg_2'), 'error');
            }

        } catch (error) {
            // return validation errors
            if (error?.response?.data?.type === "validation") {
                setErrors(error?.response?.data?.errors ?? {});
            }

            else if (error?.response?.data?.is_ok === false) {
                addAlert(error?.response?.data?.message ?? t('mssg_2'), 'error');

                if (error?.response?.data?.data?.is_incorrect_code) {
                    setErrors({ code: error?.response?.data?.message ?? t('mssg_2') })
                }
            }

        } finally {
            setIsLoading(false)
        }
    }


    /**
     * Logout
     */
    const logout = async () => {
        try {
            setIsLoading(true)

            await csrf();

            const { data } = await axiosClient.post(hash('auth/logout'));

            if (data.is_ok === true) {
                removeGlobalCookie("_uln");
                removeGlobalCookie("sharely_cookie"); // remove also this cookie (just now, because login strictur updated)

                navigate('/')
            }

        } catch (error) {
            if (error.response.data.message === 'Unauthenticated.') {
                removeGlobalCookie("_uln");
                removeGlobalCookie("sharely_cookie"); // remove also this cookie (just now, because login strictur updated)
            }

        } finally {
            setIsLoading(false)
        }
    }


    return {
        isAuth,
        authUser,
        csrf,
        login,
        register,
        isLoading,
        logout
    };
}