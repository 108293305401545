import { isNull } from "../../../../Helpers/utils";
import { Spinner } from "../../../../icons";
import dayjs from "../../../../lib/dayjs";


export default function BookNowButton({
    selectedDate,
    selectedTime,
    canBook,
    isLoading,
    onClick,
    t
}) {

    // const getTodate = dayjs(new Date()).format('DD-MM-YYYY');
    const isToday = dayjs(selectedDate).isSame(dayjs(), 'day');
    const diff = dayjs(selectedDate).diff(dayjs(), 'day');

    const getDateLabel = () => {
        if (isToday) return t('date.today');
        if (diff === 0) return t('date.tomorrow');

        // return t('date.afterDays', { count: diff });

        return diff === 1 ? t('date.afterDays', { count: diff }) : t('date.afterDays_plural', { count: diff });
    };

    const getTimeLabel = (selectedDateVal, selectedTimeVal) => {
        const selectedDate = dayjs(selectedDateVal);
        const selectedDateTime = dayjs(`${selectedDate.format('YYYY-MM-DD')} ${selectedTimeVal.replace(' PM', '').replace(' AM', '')}`);
        const now = dayjs();

        if (selectedDateTime.isBefore(now)) {
            return t('time.past'); // Translation key for 'Time has passed'
        }

        const diffInMinutes = selectedDateTime.diff(now, 'minute');
        const hours = Math.floor(diffInMinutes / 60);
        const minutes = diffInMinutes % 60;

        if (hours > 0 && minutes > 0) {
            return t('time.afterHoursAndMinutes', { hours, minutes }); // Translation key with interpolation
        } else if (hours > 0) {
            return t('time.afterHours', { hours }); // Translation key for hours
        } else {
            return t('time.afterMinutes', { minutes }); // Translation key for minutes
        }
    };

    return (
        <div className="sticky bottom-0 shadow w-full bg-white border-t border-borderColor">
            <div className="w-full max-w-5xl mx-auto px-4 py-3 flex items-center gap-4">
                <button
                    disabled={isLoading || !canBook}
                    onClick={onClick}
                    className={`outline-none whitespace-nowrap rounded-md py-3.5 px-6 transition-colors duration-200 select-none textBody-s2
                        ${!canBook ? 'bg-gray-200 text-gray-500 cursor-not-allowed' : 'text-white bg-primary hover:bg-primary/90'}
                    `}>
                    {isLoading
                        ? <Spinner className="w-5 h-5 animate-spin" />
                        : t('booking_3')
                    }
                </button>

                <div className="flex flex-col gap-0.5">
                    <span className="lg:textBody-s2 textBody-xs2 text-fontColor2">
                        {dayjs(selectedDate).format('MMMM DD, YYYY')}
                        <span className="text-fontColor2/60 ltr:ml-2 rtl:mr-2">({getDateLabel()})</span>
                    </span>
                    {!isNull(selectedTime) &&
                        <span className="lg:textBody-s2 textBody-xs2 text-fontColor2">
                            {selectedTime}
                            <span className="text-fontColor2/60 ltr:ml-2 rtl:mr-2">({getTimeLabel(dayjs(selectedDate).format('YYYY-MM-DD'), selectedTime)})</span>
                        </span>}
                </div>
            </div>
        </div>
    )
}