import { Link } from "react-router-dom";
import { Arrow } from "../../icons";
import ChangeLang from "../Language/ChangeLang";
import ModalLayout from "../../layouts/ModalLayout";
import { useTranslation } from "react-i18next";


export default function GetStarted({ close }) {

    const { t } = useTranslation()

    return (
        <ModalLayout
            wallBackground={'bg-black/80'}
            className="fixed lg:max-w-[500px] max-w-full w-full -translate-x-2/4 -translate-y-2/4 left-2/4 top-2/4 z-[1011]"
            close={close}
        >
            <div className="bg-white lg:rounded-lg lg:shadow-lg p-6 max-lg:py-8 max-sm:px-4 max-lg:h-dvh max-lg:overflow-y-auto">
                <div className="">
                    <div className="w-full flex items-start justify-between mb-4 pb-4">
                        <div className="flex flex-col gap-0.5">
                            <h2 className="textHeader-s text-fontColor">{t('auth__01')}</h2>
                            <p className="lg:textBody-s3 textBody-xs3 text-fontColor2">{t('auth__02')}</p>
                        </div>
                        <ChangeLang />
                    </div>
                    <div className="w-full flex flex-col gap-4">
                        <Link to="/?auth=login">
                            <div className="w-full rounded-lg cursor-pointer py-6 px-5 shadow-sm border border-borderColor hover:bg-primary/[0.03] transition-all duration-200">
                                <div className="flex items-center justify-between gap-2">
                                    <div className="flex flex-col gap-1">
                                        <span className="textBody-m1 text-fontColor">{t('auth__03')}</span>
                                        <p className="textBody-s3 text-fontColor2">{t('auth__04')}</p>
                                    </div>
                                    <Arrow className="w-5 h-5 rtl:rotate-180" />
                                </div>
                            </div>
                        </Link>

                        <Link to="/?auth=register">
                            <div className="w-full rounded-lg cursor-pointer py-6 px-5 shadow-sm border border-borderColor hover:bg-primary/[0.03] transition-all duration-200">
                                <div className="flex items-center justify-between gap-2">
                                    <div className="flex flex-col gap-1">
                                        <span className="textBody-m1 text-fontColor">{t('auth__05')}</span>
                                        <p className="textBody-s3 text-fontColor2">{t('auth__06')}</p>
                                    </div>
                                    <Arrow className="w-5 h-5 rtl:rotate-180" />
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </ModalLayout>
    )
}