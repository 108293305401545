import React from "react";
import DatePicker from "react-datepicker";
import dayjs from "../../../lib/dayjs";
import 'react-datepicker/dist/react-datepicker.css';


export default function CustomDayPicker({ renderCustomHeader, selected, onChange = () => { }, unavailableDates = [], availableDays = [] }) {

    const isDateUnavailable = date => {

        const now = dayjs().format("YYYY-MM-DD");
        const selectedDate = dayjs(date).format("YYYY-MM-DD");
        const selectedDayName = dayjs(date).locale("en").format("dddd").toLowerCase();
        
        if (!availableDays.includes(selectedDayName)) {
            return true;
        }

        if (unavailableDates.includes(selectedDate)) {
            return true;
        }
        
        return dayjs(selectedDate).isBefore(now);
    };

    const hanldeChangeDate = value => {
        if (isDateUnavailable(value[0])) {
            return;
        }
        onChange(dayjs(value[0]).format("YYYY-MM-DD"));
    };

    const renderDayContents = (day, date) => {
        if (isDateUnavailable(date)) {
            return <span className="unavailable">{day}</span>;
        } else {
            return <span className="available">{day}</span>;
        }
    };

    // hide days of week (holidays)
    const hideHolidays = (date) => {
        const selectedDayName_ = dayjs(date).locale("en").format("dddd").toLowerCase();

        if (!availableDays.includes(selectedDayName_)) {
            return false;
        }

        return true;
    };

    return (
        <DatePicker
            monthsShown={1}
            minDate={new Date()}
            selected={dayjs(selected).toDate()}
            onChange={hanldeChangeDate}
            selectsRange
            inline
            renderCustomHeader={renderCustomHeader}
            renderDayContents={renderDayContents}
            filterDate={hideHolidays}
        />
    );
}