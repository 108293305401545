import { useAlerts } from "../../../context/AlertsContext";
import { n } from "../../../Helpers/utils";
import { useAuth } from "../../../hooks/useAuth";
import ProfileInformations from "../../../components/Pages/Client/Profile/ProfileInformations";
import ContactDetails from "../../../components/Pages/Client/Profile/ContactDetails";
import { useTranslation } from "react-i18next";


export default function ClientAccount() {

    const { t } = useTranslation()

    const { authUser } = useAuth();

    const { addAlert } = useAlerts();

    const setAllAlerts = (text, number) => {
        addAlert(text, n(number) === 1 ? 'success' : 'error')
    }

    return (
        <div className="max-w-3xl mx-auto w-full px-4 flex flex-col lg:py-20 py-10">
            <div className="mb-3 pb-3">
                <div className="w-full flex justify-between items-center">
                    {/* Text */}
                    <div className="flex flex-col gap-1">
                        <h2 className="flex items-center textHeader-l">
                            {t("client_account_1")}
                        </h2>
                        <p className="textBody-s3">{t("client_account_2")}</p>
                    </div>
                </div>
            </div>

            <div className="mt-6 flex flex-col gap-8">

                {/* personal info */}
                <ProfileInformations
                    authUser={authUser}
                    setNotif={setAllAlerts}
                />


                {/* contact details */}
                <ContactDetails
                    authUser={authUser}
                    setNotif={setAllAlerts}
                />

            </div>

        </div>
    )
}