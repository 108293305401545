
import i18next from 'i18next';
import { useSearchParams } from 'react-router-dom';
import ModalLayout from "../../layouts/ModalLayout"
import { Check, Hide1 } from '../../icons';
import { languagesList } from '../../db/languages';
import { useTranslation } from 'react-i18next';
import { ChangeUserlanguage } from '../../lib/api';
import dayjs from '../../lib/dayjs';


export default function LanguagesSwitcher({ selectedLang, changeLang, isAuth }) {

    const { t } = useTranslation()

    //Start------------------------------ Change Locale Modal ------------------------------//

    const [searchParams, setSearchParams] = useSearchParams();
    const queryName = "qv";

    const addActionQuery = () => {
        searchParams.set(queryName, 'languages');
        setSearchParams(searchParams);
    }

    const removeActionQuery = () => {
        searchParams.delete(queryName);
        setSearchParams(searchParams);
    }

    const getActionQuery = () => {
        return searchParams.get(queryName) ?? '';
    };

    const isOpen = getActionQuery() === "languages";

    //End------------------------------ Change Locale Modal ------------------------------//

    const currentLanguage = i18next?.language ?? "en";


    // change language in front and send request to change it in DB
    const handleChangeLanguage = (lng_) => {
        changeLang(lng_) // for front

        removeActionQuery()

        dayjs.updateLocale(lng_)

        if (isAuth) {
            ChangeUserlanguage({ lang: lng_ }); // for DB
        }
    }


    return (
        <>
            <button
                onClick={addActionQuery}
                className="flex shrink-0 justify-center items-center w-10 h-10 bg-transparent rounded-full text-primaryTextColor cursor-pointer outline-none btn_effect">
                <div className="rounded-full w-5 h-5 bg-primary/10">
                    <img src={`/flags/${currentLanguage}.png`} alt="flag" className="w-full h-full rounded-full" />
                </div>
            </button>

            {isOpen &&
                <ModalLayout
                    wallBackground={'bg-black/80'}
                    className="fixed lg:max-w-[450px] w-full -translate-x-2/4 lg:-translate-y-2/4 left-2/4 lg:top-2/4 z-[1011] max-lg:bottom-0 max-lg:px-4"
                    close={removeActionQuery}
                >
                    <div className={`bg-white textBody-s3 w-full lg:rounded-lg gap-1 justify-between flex flex-col overflow-hidden rounded-t-xl`}>
                        <div className="flex items-center justify-between p-4">
                            <h3 className="textBody-m1">{t("Change language")}</h3>
                            <button
                                onClick={removeActionQuery}
                                className="rounded-lg p-1 hover:bg-primary/5">
                                <Hide1 className="w-5 h-5" />
                            </button>
                        </div>

                        <div className="py-6 px-4 flex flex-col border-y border-primaryBorder lg:max-h-[60vh] max-lg:h-full overflow-y-auto">
                            <div className="">
                                <p>{t("Set your language for the best experience")}</p>
                            </div>
                            <div className="pt-6 grid md:grid-cols-1 grid-cols-3 gap-2">
                                {languagesList?.map((lng, i) => (
                                    <button
                                        key={i}
                                        onClick={() => {
                                            handleChangeLanguage(lng.slug)
                                        }}
                                        className={`rounded-lg lg:py-4 py-3 max-md:py-4 lg:px-4 px-2 lg:textBody-s2 textBody-xs2 flex items-center justify-between gap-3 border transition-all duration-100 ease-in
                                            ${selectedLang === lng.slug ? 'max-md:border-green-400' : ' hover:border-primary/20'}`}>
                                        <div className={`flex items-center max-md:flex-col max-md:justify-center max-md:mx-auto md:gap-3 gap-1.5`}>
                                            <div className="rounded w-[35px] min-h-[26px] bg-primary2/10 overflow-hidden">
                                                <img
                                                    className="w-full h-auto"
                                                    src={`/flags/${lng.slug}.svg`}
                                                    alt={`${lng.slug}-flag`}
                                                />
                                            </div>
                                            <span>{t(lng.title)}</span>
                                        </div>

                                        {selectedLang === lng.slug &&
                                            <div className="border border-green-500 p-[1px] rounded-full max-md:hidden">
                                                <Check className="w-4 h-4 text-green-500" />
                                            </div>
                                        }
                                    </button>
                                ))}
                            </div>
                        </div>

                        {/* footer */}
                    </div>
                </ModalLayout>
            }
        </>
    )
}