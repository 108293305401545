
// set .env variables
const VARIABLES = {
    "local": process.env.REACT_APP_LOCAL ?? "",
    "appBackendUrl": process.env.REACT_APP_API_URL ?? "",
    "appFrontendUrl": process.env.REACT_APP_FRONTEND_URL ?? "",
    "appDashboardUrl": process.env.REACT_APP_DASHBOARD_URL ?? "",
    "appCookiesUrl": process.env.REACT_APP_COOKIES_URL ?? "",
};


export default function getEnv(key = "") {
    if (!key) return "";

    return VARIABLES[key] ?? "";
}