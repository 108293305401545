import { useState } from "react";
import { useTranslation } from "react-i18next";
import { isMoroccanNumber, isNull, mergePhoneNumber } from "../../../Helpers/utils";
import { useAuth } from "../../../hooks/useAuth";
import ModalLayout from "../../../layouts/ModalLayout";
import LoginForm from "./LoginForm";
import PhoneVerification from "./PhoneVerification";


export default function AuthenticationLogin({ close, addAlert, showRegisterForm }) {

    const { t } = useTranslation()

    const { login, isLoading } = useAuth()

    const [phone, setPhone] = useState('')
    const [phoneCountry, setPhoneCountry] = useState({})
    const [confirmationCode, setConfirmationCode] = useState('')


    const [phoneIsCurrect, setPhoneIsCurrect] = useState(" ")
    const fullPhoneNumber = mergePhoneNumber(phoneCountry?.phone_code, phone);
    const whatsappNumber = isMoroccanNumber(fullPhoneNumber);

    const [errors, setErrors] = useState({})


    const [showEmailConfirmationCode, setShowEmailConfirmationCode] = useState(false)


    const handleSubmit = async (e) => {
        e.preventDefault()

        setErrors({})

        if (!isNull(confirmationCode)) {
            setShowEmailConfirmationCode(true)
            return;
        }

        if (!phoneIsCurrect || isNull(phone)) {
            addAlert(t('auth_section_36'), 'error')
            return;
        }

        login({
            showVerificationBox: setShowEmailConfirmationCode,
            setErrors,
            phone: whatsappNumber,
            code: confirmationCode,
        })
    }


    const handlePhoneVerification = async () => {

        if (isNull(confirmationCode)) return;

        login({
            showVerificationBox: setShowEmailConfirmationCode,
            setErrors,
            phone: whatsappNumber,
            code: confirmationCode,
        })
    }


    return (
        <ModalLayout
            wallBackground={'bg-black/80'}
            className="fixed lg:max-w-[500px] max-w-full w-full -translate-x-2/4 -translate-y-2/4 left-2/4 top-2/4 z-[1011]"
            close={close}
        >
            <div className="bg-white lg:rounded-lg lg:shadow-lg p-6 max-lg:py-8 max-sm:px-4 max-lg:h-dvh max-lg:overflow-y-auto">

                {showEmailConfirmationCode ? (
                    <PhoneVerification
                        verify={handlePhoneVerification}
                        cancel={() => setShowEmailConfirmationCode(false)}
                        setConfirmationCode={setConfirmationCode}
                        confirmationCode={confirmationCode}
                        fullPhoneNumber={fullPhoneNumber}
                        isLoading={isLoading}
                        errors={errors}
                        setErrors={setErrors}
                        t={t}
                    />
                ) : (
                    <LoginForm
                        handleSubmit={handleSubmit}
                        phone={phone}
                        setPhone={setPhone}
                        phoneCountry={phoneCountry}
                        setPhoneCountry={setPhoneCountry}
                        errors={errors}
                        phoneIsCurrect={phoneIsCurrect}
                        setPhoneIsCurrect={setPhoneIsCurrect}
                        isLoading={isLoading}
                        close={close}
                        t={t}
                        showRegisterForm={showRegisterForm}
                    />
                )}

            </div>
        </ModalLayout>
    );
}