import Content from "./Content";
import SideBar from "./SideBar";
import { GetAllAppointmentsByClient } from "../../../lib/api";
import { isNull } from "../../../Helpers/utils";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";


export default function ClientAppointments() {

    const { t } = useTranslation()

    const [pageNumber, setPageNumber] = useState(1);
    const [searchParams] = useSearchParams();
    const statusParam = searchParams.get("status") ?? 'all';

    
    const paramsData = {
        ...(pageNumber !== 1 && { page: pageNumber }),
        ...(!isNull(statusParam) && statusParam !== "all" && { status: statusParam }),
    };


    const { data, loading } = GetAllAppointmentsByClient(paramsData);
    const { appointments, stats } = data?.data ?? {};


    return (
        <div className="max-w-6xl mx-auto w-full px-4 flex flex-col lg:py-20 py-10">
            <div className="mb-3 pb-3">
                <h2 className="textHeader-s">{t('client_appo_1')}</h2>
            </div>
            <div className="w-full max-w-full flex max-lg:flex-col xl:gap-6 lg:gap-4 gap-4">

                <SideBar
                    stats={stats}
                    statusParam={statusParam}
                />

                <Content
                    loading={loading}
                    appointments={appointments}
                    setPageNumber={setPageNumber}
                />

            </div>
        </div>
    )
}