import i18next from "i18next";
// import getEnv from "../../Helpers/env";


export default function NavLogo() {

    const currentLanguage = i18next?.language ?? "en";

    const url = '/';//getEnv('appFrontendUrl');

    const logoPath = `${url}images/sharely-logo-${currentLanguage === "ar" ? 'ar' : 'en'}.png`;

    return (
        <div className="flex items-center gap-4">
            <img src={logoPath} alt="logo" className="lg:h-[25px] h-[20px] w-auto -mb-[5px] cursor-pointer" />
        </div>
    )
}