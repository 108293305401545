
const NotFound = () => {
    return (
        <section className="bg-white">
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-sm text-center">
                    <h1 className="mb-4 textHeader-2xl text-fontColor">404</h1>
                    <p className="mb-4 textHeader-m text-fontColor">Something's missing.</p>
                    <p className="mb-4 textBody-s3 text-fontColor2">Sorry, we can't find that page. You'll find lots to explore on the home page. </p>
                    <a href="/" className="inline-flex text-white bg-primary hover:bg-primary/80 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4">Back to Homepage</a>
                </div>
            </div>
        </section>
    )
}
export default NotFound;