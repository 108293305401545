import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NotFound from './components/NotFound';
import Layout from './layouts/Layout';
import ScrollToTop from './components/ScrollToTop';
import FixSiteDNS from './components/FixSiteDNS';
import ClearCookies from './components/ClearCookies';
import { AlertsProvider } from './context/AlertsContext';
import AlertsList from './components/Alerts/AlertsList';
import HomePage from './pages/HomePage';
import Salon from './pages/Salon';
import ClientAppointments from './pages/ClientPages/ClientAppointments';
import ClientAccount from './pages/ClientPages/ClientAccount';
import ClientLayout from './layouts/ClientLayout';
import Legal from './layouts/Legal';
import PrivacyPolicy from './pages/Legal/PrivacyPolicy';
import TermsAndConditions from './pages/Legal/TermsAndConditions';



export default function App() {
    return (
        <AlertsProvider>
            <AlertsList />
            <BrowserRouter>
                <ScrollToTop />
                <FixSiteDNS />
                <ClearCookies />

                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<HomePage />} />
                    </Route>

                    <Route path="/client" element={<ClientLayout />}>
                        <Route path='appointments' element={<ClientAppointments />} />
                        <Route path='account' element={<ClientAccount />} />
                    </Route>

                    <Route path='/:slug' element={<Salon />} />


                    <Route path="/" element={<Legal />}>
                        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                        <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
                    </Route>

                    <Route path='*' element={<NotFound />}></Route>
                </Routes>
            </BrowserRouter>
        </AlertsProvider>
    );
}
