import { Link } from "react-router-dom";
import { renderImage } from "../../../Helpers/utils";
import dayjs from "../../../lib/dayjs";


export default function AppointmentCard({ appo, t }) {

    const { booking_date, booking_time, booking_status, salon } = appo;

    const appoDate = dayjs(`${dayjs(booking_date).format('YYYY-MM-DD')} ${booking_time}`);

    return (
        <div className="lg:p-4 p-3 w-full flex justify-between bg-white border border-borderColor/50 hover:border-borderColor rounded-lg cursor-context-menu select-none overflow-hidden">
            <div className="flex items-start gap-2">
                <div>
                    <Link to={`/${salon?.slug}`}>
                        <div className="lg:w-[70px] lg:h-[70px] w-[50px] h-[50px] bg-primary/10 bg-cover bg-center rounded-lg mx-auto"
                            style={{ backgroundImage: `url("${renderImage(salon?.images)}")` }}>
                        </div>
                    </Link>
                </div>
                <div className="flex flex-col gap-0.5">
                    <span className="lg:textBody-m2 textBody-s2 text-fontColor line-clamp-1">{salon?.name}</span>
                    <div className="text-fontColor2 flex flex-col gap-0.5">
                        <span className="lg:textBody-xs3 textBody-2xs3 ltr:mr-2 rtl:ml-2">{dayjs(appoDate).format('dddd, MMMM D')}</span>
                        <span className="lg:textBody-xs3 textBody-2xs3">{dayjs(appoDate).format('HH:mm a')}</span>
                    </div>
                </div>
            </div>

            <div className="flex items-center gap-2">
                <button
                    className={`lg:px-3.5 px-2.5 py-2 rounded-md lg:textBody-xs2 textBody-2xs2 transition cursor-context-menu ${renderStatusBadge(booking_status)}`}>
                    {t(renderAppoStatusName(booking_status))}
                </button>
            </div>
        </div>
    );
}

const renderStatusBadge = (status) => {
    switch (status) {
        case 'active':
            return "bg-green-100 text-green-500";
        case 'confirmed':
            return "bg-blue-100 text-blue-500";
        case 'canceled':
            return "bg-red-100 text-red-500";
        case 'completed':
            return "bg-gray-100 text-gray-500";
        default:
            return "bg-gray-100 text-gray-500";
    }
};

const renderAppoStatusName = (stts) => {
    switch (stts) {
        case 'active':
            return "dash_appo_8";
        case 'confirmed':
            return "dash_appo_9";
        case 'canceled':
            return "dash_appo_11";
        case 'completed':
            return "dash_appo_10";
        default:
            return "";
    }
}