import Cookies from 'js-cookie'
import getEnv from '../Helpers/env'

export default function useCookies () {
    
    const setGlobalCookie = ($n, $v) => {
        Cookies.set($n, $v, {
            expires: new Date(2147483647 * 1000),
            secure: true,
            sameSite: 'strict',
            domain: getEnv('appCookiesUrl'),
            path: '/'
        })
    }

    const removeGlobalCookie = ($n) => {
        Cookies.remove($n, {
            domain: getEnv('appCookiesUrl'),  // Ensure it matches the domain used when setting the cookie
            path: '/',                        // Same path as when setting the cookie
            secure: true,                     // Same security flag if used during setting
            sameSite: 'strict'                // Same `sameSite` setting if applicable
        });
    };

    const setCookie = ($n, $v) => {
        Cookies.set($n, $v, {
            expires: new Date(2147483647 * 1000),
            secure: true,
            sameSite: 'strict',
            path: '/'
        })
    }

    const getCookie = ($n) => {
        return Cookies.get($n) ?? false;
    }

    const removeCookie = ($n) => {
        Cookies.remove($n)
    }

    return {
        setGlobalCookie,
        removeGlobalCookie,
        setCookie,
        getCookie,
        removeCookie
    }
}