import { useNavigate } from "react-router-dom";
import AvatarLoader from "../Shared/Avatar/AvatarLoader";
import { useState } from "react";
import Avatar from "../Shared/Avatar"
import { getFullName, renderImage } from "../../Helpers/utils";
import ModalLayout from "../../layouts/ModalLayout"
import { Spinner } from "../../icons";
import { useTranslation } from "react-i18next";


export default function AvatarDropDown({ authUser, logout, inProgress }) {

    const { t } = useTranslation()

    const navigate = useNavigate()

    const [isOpen, setIsOpen] = useState(false)
    const toggleDropDown = () => setIsOpen(!isOpen)

    const GorTo = (path) => {
        navigate(path)
        toggleDropDown()
    }

    return authUser.isLoading ? <AvatarLoader size={'lg:w-10 lg:h-10 w-8 h-8'} /> : (
        <div className="relative">
            <div onClick={toggleDropDown} className="flex items-center gap-1 cursor-pointer">
                <Avatar
                    src={renderImage(authUser?.data?.avatar)}
                    size={'lg:w-10 lg:h-10 w-8 h-8'}
                    alt={getFullName(authUser?.data)}
                />
            </div>

            {isOpen && (
                <ModalLayout
                    wallBackground={`bg-black/20`}
                    className={`absolute ltr:right-0 rtl:left-0 z-[1012]`}
                    close={toggleDropDown}
                >
                    <div className="shadow-lg bg-white animation-01 animation-02 w-[300px] h-full rounded-lg overflow-hidden pb-2 flex flex-col textBody-s3">
                        <div
                            onClick={() => GorTo('/dashboard/account')}
                            className="group flex items-center gap-2 px-4 border-b border-fontColor2/10 pt-4 pb-4 mb-2 select-none cursor-pointer">
                            <Avatar
                                src={renderImage(authUser?.data?.avatar)}
                                size={'lg:w-10 lg:h-10 w-8 h-8'}
                                alt={getFullName(authUser?.data)}
                            />
                            <div className="flex flex-col gap-0">
                                <h3 className="line-clamp-1 textBody-s1 group-hover:underline">{getFullName(authUser?.data)}</h3>
                                <span className="line-clamp-1 textBody-xs2 text-fontColor2/80">{authUser?.data?.phone}</span>
                            </div>
                        </div>
                        <button
                            onClick={() => GorTo('/dashboard/account')}
                            className="text-start whitespace-nowrap py-2.5 px-4 hover:bg-primary/5 transition-colors duration-300">
                            {t("dash_header_menu_1")}
                        </button>
                        <button
                            onClick={() => GorTo('/dashboard/settings')}
                            className="text-start whitespace-nowrap py-2.5 px-4 hover:bg-primary/5 transition-colors duration-300">
                            {t('dash_header_menu_6')}
                        </button>

                        <div className="w-full h-[1px] bg-primary/5 my-2"></div>

                        <button
                            onClick={() => GorTo('/dashboard/new-ticket')}
                            className="text-start whitespace-nowrap py-2.5 px-4 hover:bg-primary/5 transition-colors duration-300">
                            {t("header_menu_4")}
                        </button>

                        <button
                            disabled={inProgress}
                            onClick={() => {
                                logout()
                            }}
                            className={`text-start whitespace-nowrap py-2.5 px-4 transition-colors duration-300 flex items-center justify-between ${inProgress ? 'bg-primary/5' : 'hover:bg-primary/5'}`}>
                            <span>{t("dash_header_menu_4")}</span>
                            {inProgress ? <Spinner className="w-4 h-4 animate-spin" /> : null}
                        </button>
                    </div>
                </ModalLayout>
            )}
        </div>
    )
}